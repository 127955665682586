


import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
function Whatsapp() {
    return (
        <WhatsAppWidget phoneNumber={process.env.NEXT_PUBLIC_WHATSAPP_NUM} />

    )
}

export default Whatsapp