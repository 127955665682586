export const headerTitle = [
    {
        title: 'Home',
        link: '/'
    },
    {
        title: 'About Us',
        link: '/about-us'
    },
    {
        title: 'Book Online',
        link: '/book-online'
    },
    {
        title: 'Support',
        link: '/support'
    },
    {
        title: 'Airport Transfer',
        link: '/airport-transfer'

    },
    {
        title: 'Contact Us',
        link: '/contact-us'
    },
]