export const company = {
  name: "A2Btommyfield Cars",
  address: {
    building: "179",
    street: "Henshaw St",
    town: "Oldham, United Kingdom",
    postcode: "OL1 2BP",
  },
  phone: "0161 213 1111",
  company_email: "a2btommyfieldcars@gmail.com",
  dpo: "",
  vat_number: "-",
  compnay_number: "-",
  website: "",
  dpo_reg_number: "-",
  updated_on: "12/12/2022",
  Copyright_year: "2019",
  logo_url: "/assets/images/logo.png",
  logo_alt: "a2b Taxis Logo",
  width: "180",
  height: "70",
  width_mobile: "180",
  height_mobile: "70",
  contactus_task_url: "https://",
  website_url: "https://www.a2btommyfieldcars.com/",
};
// Check List - make sure update below files before making this website live
// 1- robots_txt: 'update robots.txt file domain details
// 1-   robots_txt: 'update robots.txt file domain details
// 2-  regenarate manifest.json file and icons and put in public folder
