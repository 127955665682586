
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import { ThemeProvider } from '@emotion/react'
import { StyledEngineProvider } from '@mui/material'
import '../styles/globals.css'
import FeedbackWrapper from '@components/wrapper/FeedbackWrapper'

import Layout from '@common/Layout'
import theme from 'theme'
import Whatsapp from '@components/whatsapp/Index'
import Head from 'next/head'

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/icon.png"></link>
        <meta name="theme-color" content="#58ade0" />
      </Head>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <FeedbackWrapper>
            <Layout>
              {
                process.env.NEXT_PUBLIC_WHATSAPP_FLAG === 'true' &&
                <div className='z-50   fixed md:right-5 right-2 bottom-3 md:bottom-3'>
                  <Whatsapp />
                </div>
              }
              <Component {...pageProps} />
            </Layout>
          </FeedbackWrapper>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  )

}

export default MyApp
