import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
export const socialLinks = [
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/A2BTommyfieldCars/',
    icon: <FacebookIcon className='cursor-pointer text-inherit ' />,
  },
  {
    title: 'Twitter',
    link: '',
    icon: <TwitterIcon className='cursor-pointer text-inherit ' />,
  },
  {
    title: 'Instagram',
    link: '',
    icon: <InstagramIcon className='cursor-pointer text-inherit ' />,
  },
  // {
  //   title: 'LinkedIn',
  //   link: '',
  //   icon: <LinkedInIcon className='cursor-pointer text-inherit ' />,
  // }


]