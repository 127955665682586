import { company } from "@components/config/Company";
import Image from "next/image";
import React from "react";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MailIcon from "@mui/icons-material/Mail";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import { headerTitle } from "@components/config/header";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { socialLinks } from "@components/config/SocialLinks";
import Link from "next/link";
import { useRouter } from "next/router";
function Footer() {
  const router = useRouter();
  return (
    <div className="bg-primary px-4 md:px-32 py-5 ">
      <div className=" py-5 grid grid-cols-1 md:grid-cols-3 gap-10 border-b border-white/50">
        <div className="bg-primary py-2 flex flex-col md:justify-start items-center md:items-start justify-center ">
          <Image src={company.logo_url} alt={company.logo_alt} width={100} height={100} />
          <Link href={`tel:${company.phone}`}>
            <a className="flex md:flex-row flex-col items-center gap-2 my-4">
              <ContactPhoneIcon className="text-md text-secondary" />
              <div className="text-white/70 hover:text-secondary hover:underline cursor-pointer">{company.phone}</div>
            </a>
          </Link>
          <Link href={`mailto:${company.company_email}`}>
            <a className="flex md:flex-row flex-col items-center gap-2 my-4">
              <MailIcon className="text-md text-secondary" />
              <div className="text-white/70 hover:text-secondary hover:underline cursor-pointer">{company.company_email}</div>
            </a>
          </Link>
          <div className="flex md:flex-row flex-col items-center gap-2 my-4">
            <PermContactCalendarIcon className="text-md text-secondary" />
            <div className="text-white/70 hover:text-secondary hover:underline cursor-pointer md:text-left text-center">
              {company.address.building} {company.address.street}, {company.address.postcode} {company.address.town}
            </div>
          </div>
        </div>
        <div className="">
          <div className="text-white w-[160px] md:mx-0 mx-auto text-md mb-4">Useful Links</div>
          {headerTitle.map((item, index) => {
            return (
              <div
                onClick={() => {
                  router.push(item.link);
                }}
                key={index}
                className="flex w-[160px] md:mx-0 mx-auto  gap-2"
              >
                <ArrowRightAltIcon className="text-secondary" />
                <div className="text-white/70 hover:text-secondary cursor-pointer hover:underline  text-md">{item.title}</div>
              </div>
            );
          })}
          <div
            onClick={() => {
              router.push("/legal/privacy-policy");
            }}
            className="flex w-[160px] md:mx-0 mx-auto  gap-2"
          >
            <ArrowRightAltIcon className="text-secondary" />
            <div className="text-white/70 hover:text-secondary cursor-pointer hover:underline  text-md">Privacy Policy</div>
          </div>
        </div>
        <div className="flex md:items-start md:justify-start items-center justify-center flex-col gap-1">
          <div className="text-white text-md mb-4">Follow Us</div>
          {socialLinks.map((item, index) => {
            return (
              <Link key={index} href={item.link}>
                <a target={"_blank"} className="flex items-center gap-1 w-[90px]">
                  <div className="text-white/70 hover:text-secondary cursor-pointer hover:underline  text-sm">{item.icon}</div>
                  <div className="text-white/70 hover:text-secondary cursor-pointer hover:underline  text-sm">{item.title}</div>
                </a>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="flex md:flex-row flex-col items-center justify-between pt-4">
        <div className="text-white/70 md:text-left text-center text-sm py-5">
          © {company.name} {company.Copyright_year}. All rights reserved.
        </div>
        <div>
          <Link href={"https://xiots.io/"}>
            <a target={"_blank"}>
              <Image src={"/assets/images/Xiots.webp"} alt={"Xiots"} width={100} height={40} />
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
