import { company } from '@components/config/Company'
import Image from 'next/image'
import React from 'react'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import MailIcon from '@mui/icons-material/Mail';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { headerTitle } from '@components/config/header';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
function Header() {
    const router = useRouter();
    const path = router.pathname;
    const [drawer, setDrawer] = React.useState(false)
    return (
        <div>
            <div className='bg-primary py-2 md:px-40 px-4 flex flex-row  items-center justify-between'>
                <Link href={'/'}>
                    <a>
                        <Image
                            src={company.logo_url}
                            alt={company.logo_alt}
                            width={118}
                            height={118}
                        />
                    </a>
                </Link>
                <Link href={`tel:${company.phone}`}>
                    <a className='md:flex items-center gap-2 hidden my-4'>

                        <ContactPhoneIcon className='text-md text-secondary' />
                        <div className='text-white/70 hover:text-secondary hover:underline cursor-pointer'>
                            {company.phone}
                        </div>
                    </a>
                </Link>
                <Link href={`mailTo:${company.company_email}`}>
                    <a className='md:flex hidden items-center gap-2 my-4'>
                        <MailIcon className='text-md text-secondary' />
                        <div className='text-white/70 hover:text-secondary hover:underline cursor-pointer'>
                            {company.company_email}
                        </div>
                    </a>
                </Link>
                <div className='md:flex hidden items-center gap-2'>

                    <PermContactCalendarIcon className='text-md text-secondary' />

                    <div className='text-white'>
                        {company.address.building} {company.address.street}, {company.address.postcode} {company.address.town}
                    </div>
                </div>

                <div onClick={() => { setDrawer(true) }} className='md:hidden block'>
                    <MenuIcon className='text-white text-3xl cursor-pointer' />

                </div>
            </div>
            <div className='md:flex hidden items-center justify-center gap-8  bg-black'>
                {
                    headerTitle.map((item, index) => {
                        return (
                            <div onClick={() => { router.push(item.link) }} key={index} className={`${path === item.link ? "bg-secondary" : " hover:bg-secondary transition-all text-white hover:text-black cursor-pointer"} py-3 px-2`}>
                                <div className='flex items-center gap-2 py-1'>
                                    <div className={` text-lg cursor-pointer`}>
                                        {item.title}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    )
                }
            </div>
            <Drawer
                anchor={'right'}
                open={drawer}
                onClose={() => { setDrawer(false) }}
            >
                <div className='h-full py-10  bg-black'>
                    {
                        headerTitle.map((item, index) => {
                            return (
                                <div onClick={() => { router.push(item.link), setDrawer(false) }} key={index} className={`${path === item.link ? "bg-secondary" : " hover:bg-secondary transition-all text-white hover:text-black cursor-pointer"} py-3 px-2`}>
                                    <div className='flex items-center gap-2 py-1'>
                                        <div className={` text-lg cursor-pointer`}>
                                            {item.title}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )
                    }
                </div>
            </Drawer>
        </div>
    )
}

export default Header